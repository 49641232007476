.resume-card-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;
}
  
.resume-image-container {
  flex: 1;
  padding: 20px;
}

.resume-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.resume-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.resume-text-container h1 {
  width: 70%;
  text-align: left;
}

.resume-text-container p {
  width: 70%;
  text-align: left;
}

.resume-bold-text {
  font-weight: bold;
}

@media (max-width: 768px) {
  .resume-card-layout {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .resume-image-container {
    flex: none;
  }

  .resume-text-container {
    flex: none; 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}