.project-card-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;
}
  
.project-image-container {
  flex: 1;
  padding: 20px;
}

.project-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-text-container h1 {
  width: 70%;
  text-align: left;
}

.project-text-container p {
  width: 70%;
  text-align: left;
}

.bold-text {
  font-weight: bold;
}

.tags {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.links {
  width: 70%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icon-link {
  max-height: 12px;
  margin-right: 5px;
}

.project-link {
  padding-right: 15px;
}

.project-tag {
  background-color: #D70015;
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

.links a{
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .project-card-layout {
    flex-direction: column; 
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .project-image-container {
    flex: none;
  }

  .project-text-container {
    flex: none; 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}