nav {
  position: sticky;
  top: 0;
  background-color: #F2F2F4;
  display: flex;
  justify-content: flex-end;
}
  
ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

li {
  margin: 0 10px;
}

a {
  color: black;
  text-decoration: none;
  font-size: 20px;
}

.nav-selected a {
  color: #D70015;
  padding-bottom: 12px;
  border-bottom: 2px solid #D70015;
  font-size: 20px;
}

@media (max-width: 768px) {
  a {
    color: black;
    text-decoration: none;
    font-size: 14px;
  }

  .nav-selected a {
    color: #D70015;
    padding-bottom: 14px;
    border-bottom: 2px solid #D70015;
    font-size: 14px;
  }

  nav {
    position: sticky;
    top: 0;
    background-color: #F2F2F4;
    display: flex;
    justify-content: space-around;
  }
}