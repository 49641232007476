.about-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
  
.about-image-container {
  flex: 1;
  padding: 20px;
}

.about-image-container img {
  padding-left: 5px;
  padding-right: 5px;
}

.about-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.about-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about-text-container h1 {
  width: 70%;
  text-align: left;
}

.about-text-container p {
  width: 70%;
  text-align: left;
}

.about-text-container button {
  background-color: #D70015;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .about-layout {
    flex-direction: column;
    align-items: center;
  }

  .about-image-container {
    flex: none;
  }

  .about-text-container {
    flex: none; 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}